import(/* webpackMode: "eager" */ "/frontend/node_modules/.pnpm/@mui+material@5.16.1_@emotion+react@11.11.4_@types+react@18.3.11_react@18.3.1__@emotion+style_j4npnjmpclh6bjflqdl2rx5bwa/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@core/components/scroll-to-top/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SettingsProvider"] */ "/frontend/src/@core/contexts/settingsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@layouts/components/horizontal/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@layouts/components/horizontal/LayoutContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@layouts/components/vertical/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@layouts/components/vertical/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@layouts/LayoutWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SessionProvider"] */ "/frontend/src/@layouts/SessionContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@layouts/styles/horizontal/StyledContentWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/@layouts/VerticalLayout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HorizontalNavProvider"] */ "/frontend/src/@menu/contexts/horizontalNavContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["VerticalNavProvider"] */ "/frontend/src/@menu/contexts/verticalNavContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/AuthRedirect.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/horizontal/FooterContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/horizontal/Header.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/shared/DisclaimerUpload.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/shared/GoToHome.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/shared/LanguageDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/shared/ModeDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/shared/PannelloUtenti.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/shared/UserDropdown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/shared/UserState.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/layout/vertical/FooterContent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/components/theme/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["NextAuthProvider"] */ "/frontend/src/contexts/nextAuthProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/frontend/src/libs/styles/AppReactToastify.tsx");

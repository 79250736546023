'use client'

// React Imports
import {useEffect, useRef, useState} from 'react'

// Next Imports
import Link from 'next/link'
import {usePathname, useParams} from 'next/navigation'

// MUI Imports
import IconButton from '@mui/material/IconButton'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'

// Type Imports
import type {Locale} from '@configs/i18n'

// Hook Imports
import {useSettings} from '@core/hooks/useSettings'
import {getDictionary} from "@/utils/getDictionary";
import Tooltip from "@mui/material/Tooltip";

type LanguageDataType = {
    langCode: Locale
    langName: string
}

const getLocalePath = (pathName: string, locale: string) => {
    if (!pathName) return '/'
    const segments = pathName.split('/')

    segments[1] = locale

    return segments.join('/')
}

// Vars
const languageData: LanguageDataType[] = [
    {
        langCode: 'it',
        langName: 'Italian'
    }, {
        langCode: 'en',
        langName: 'English'
    },
]

const LanguageDropdown = () => {
    // States
    const [open, setOpen] = useState(false)

    // Refs
    const anchorRef = useRef<HTMLButtonElement>(null)

    // Hooks
    const pathName = usePathname()
    const {settings} = useSettings()
    const {lang} = useParams()

    const handleClose = () => {
        setOpen(false)
    }

    const handleToggle = () => {
        setOpen(prevOpen => !prevOpen)
    }


    const {lang: locale}: any = useParams()
    const [t, setTranslations]: any = useState({})
    useEffect(() => {
        getDictionary(locale as Locale).then(dictionary => {
            setTranslations(dictionary)
        })
    }, []);

    function translate(key: string) {
        return t[locale]?.[key] || key
    }

    return (
        <>
            <IconButton ref={anchorRef} onClick={handleToggle} className='text-textPrimary'>
                <i className='material-symbols--language'/>
            </IconButton>
            <Popper
                open={open}
                transition
                disablePortal
                placement='bottom-start'
                anchorEl={anchorRef.current}
                // className='min-is-[160px] !mbs-3 z-[1]'
                className='!mbs-3 z-[1]'
            >
                {({TransitionProps, placement}) => (
                    <Fade
                        {...TransitionProps}
                        style={{transformOrigin: placement === 'bottom-start' ? 'left top' : 'right top'}}
                    >
                        <Paper className={settings.skin === 'bordered' ? 'border shadow-none' : 'shadow-lg'}
                               style={{backgroundColor: "#041F5C"}}>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList onKeyDown={handleClose}>
                                    {languageData.map(locale => (
                                        <Tooltip key={locale.langCode} title={translate(locale.langName)} placement="right">
                                            <MenuItem

                                                component={Link}
                                                href={getLocalePath(pathName, locale.langCode)}
                                                onClick={handleClose}
                                                selected={lang === locale.langCode}
                                            >

                                                <img src={'/images/avatars/' + locale.langCode + '.svg'} width={26}
                                                     alt={translate(locale.langName)}/>

                                                {/*{translate(locale.langName)}*/}
                                            </MenuItem>
                                        </Tooltip>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Fade>
                )}
            </Popper>
        </>
    )
}

export default LanguageDropdown

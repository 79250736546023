'use client'
import React, {createContext, useContext, useState, useEffect} from 'react';
import {signOut, useSession} from "next-auth/react";
import {getLocalizedUrl} from '@/utils/i18n';
import {useParams, useRouter} from "next/navigation";
import type {Locale} from '@/configs/i18n'

// Creazione del contesto per la sessione
const SessionContext = createContext(null);

// Hook per accedere al contesto
export const useSessionContext = () => useContext(SessionContext);

// Componente Provider per la sessione
export const SessionProvider = ({children}: any) => {
    const [session, setSession]: any = useState(null);
    const mySession: any = useSession();
    const {lang: locale}: any = useParams()
    const router = useRouter()

    useEffect(() => {
        if (mySession.status === 'authenticated') {
            setSession(mySession.data)

            if (!mySession?.data?.tokenApi) {
                router.push(getLocalizedUrl('/auth', locale as Locale))
            }
        }
    }, [mySession.status]);


    async function logOut() {
        localStorage.clear()
        router.push(getLocalizedUrl('/login', locale as Locale))
        await signOut({redirect: false})
    }

    return mySession.status === 'authenticated' ? (
        <SessionContext.Provider value={session}>
            {children}
        </SessionContext.Provider>
    ) : null;

    // return (
    //     <SessionContext.Provider value={session}>
    //         {children}
    //     </SessionContext.Provider>
    // );
};
'use client'

// React Imports
import {useEffect, useRef, useState} from 'react'
import type { MouseEvent } from 'react'

// Next Imports
import { useParams, useRouter } from 'next/navigation'

// MUI Imports
import { styled } from '@mui/material/styles'
import Badge from '@mui/material/Badge'
import Avatar from '@mui/material/Avatar'
import Popper from '@mui/material/Popper'
import Fade from '@mui/material/Fade'
import Paper from '@mui/material/Paper'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import MenuList from '@mui/material/MenuList'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'

// Third-party Imports
import { signOut, useSession } from 'next-auth/react'

// Type Imports
import type { Locale } from '@configs/i18n'

// Hook Imports
import { useSettings } from '@core/hooks/useSettings'

// Util Imports
import { getLocalizedUrl } from '@/utils/i18n'
import {getDictionary} from "@/utils/getDictionary";
import {string} from "valibot";
import moment from "moment";

// Styled component for badge content
const BadgeContentSpan = styled('span')({
  width: 8,
  height: 8,
  borderRadius: '50%',
  cursor: 'pointer',
  backgroundColor: 'var(--mui-palette-success-main)',
  boxShadow: '0 0 0 2px var(--mui-palette-background-paper)'
})

const UserDropdown = () => {
  const { lang: locale } = useParams()
  const [t, setTranslations]: any = useState({})
  useEffect(() => {
    getDictionary(locale as Locale).then(dictionary => {
      setTranslations(dictionary)
    })
  }, []);
  function translate(key: string, group: any = locale) {
    return t[group][key] || key
  }


  // States
  const [open, setOpen] = useState(false)

  // Refs
  const anchorRef = useRef<HTMLDivElement>(null)

  // Hooks
  const router = useRouter()
  const { data: session } = useSession()
  const mySession: any = session
  const { settings } = useSettings()
  const user: any = session?.user
  const lastConnection: any = user?.lastConnection

  const handleDropdownOpen = () => {
    !open ? setOpen(true) : setOpen(false)
  }

  const handleDropdownClose = (event?: MouseEvent<HTMLLIElement> | (MouseEvent | TouchEvent), url?: string) => {
    if (url) {
      router.push(getLocalizedUrl(url, locale as Locale))
    }

    if (anchorRef.current && anchorRef.current.contains(event?.target as HTMLElement)) {
      return
    }

    setOpen(false)
  }

  const handleUserLogout = async () => {
    try {
      // Redirect to login page
      signOut({ redirect: false }).then(()=> {
        localStorage.clear()
        router.push(getLocalizedUrl('/login', locale as Locale))
      })
    } catch (error) {
      console.error(error)

      // Show above error in a toast like following
      // toastService.error((err as Error).message)
    }
  }

  return (
    <>
      <Badge
        ref={anchorRef}
        overlap='circular'
        badgeContent={<BadgeContentSpan onClick={handleDropdownOpen} />}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        className='mis-2'
      >
        <Avatar
          ref={anchorRef}
          alt={session?.user?.name || ''}
          src={session?.user?.image || ''}
          onClick={handleDropdownOpen}
          className='cursor-pointer bs-[38px] is-[38px]'
        />
      </Badge>
      <Popper
        open={open}
        transition
        disablePortal
        placement='bottom-end'
        anchorEl={anchorRef.current}
        className='min-is-[240px] !mbs-3 z-[5]'
      >
        {({ TransitionProps, placement }) => (
          <Fade
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom-end' ? 'right top' : 'left top'
            }}
          >
            <Paper className={settings.skin === 'bordered' ? 'border shadow-none' : 'shadow-lg'} style={{backgroundColor: "#041F5C"}}>
              <ClickAwayListener onClickAway={e => handleDropdownClose(e as MouseEvent | TouchEvent)}>
                <MenuList>
                  <div className='flex items-center plb-2 pli-6 gap-2' tabIndex={-1}>
                    {/*<Avatar alt={session?.user?.name || ''} src={session?.user?.image || ''} />*/}
                    <div className='flex items-start flex-col'>
                      <Typography style={{alignSelf: 'flex-end'}} className='font-medium align-end'
                                  // color='text.primary'
                                   color='#FFFFFF'
                      >
                        {session?.user?.name ? session?.user?.name?.charAt(0).toUpperCase() + session?.user?.name.slice(1) : ''}
                      </Typography>
                      {/*<Typography variant='body1' color='#FFFFFF'>{session?.user?.email || ''}</Typography>*/}
                      <Typography variant='body2' color='#FFFFFF'>{translate('ip')}{lastConnection?.ip ? lastConnection?.ip : 'N/A'} </Typography>
                      <Typography variant='body2' color='#FFFFFF'>{translate('ultimo-acc')}{lastConnection?.date ? moment(lastConnection?.date).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY')}</Typography>
                      <Typography variant='body2' color='#FFFFFF'>{translate('ultimo-acc-ore')}{lastConnection?.date ? moment(lastConnection?.date).format('HH:mm:ss') : moment().format('HH:mm:ss')}</Typography>
                    </div>
                  </div>
                  {/*{mySession?.user?.role === 1 && (*/}
                  {/*    <MenuItem className='mli-2 gap-3' onClick={e => handleDropdownClose(e, '/PannelloRuoli')}>*/}
                  {/*      <i className='tabler-settings' style={{color:'#FFFFFF'}} />*/}
                  {/*      <Typography color='#FFFFFF'>Pannello Ruoli</Typography>*/}
                  {/*    </MenuItem>*/}
                  {/*)}*/}
                  <Divider className='mlb-1' color='#FFFFFF' />
                  <MenuItem className='mli-2 gap-3' onClick={e => handleDropdownClose(e, '/CambioPassword')}>
                    <i className='tabler-lock-open' style={{color:'#FFFFFF'}} />
                    <Typography color='#FFFFFF'>{translate('cambia-pass')}</Typography>
                  </MenuItem>
                  <MenuItem className='mli-2 gap-3' onClick={handleUserLogout}>
                    <i className='tabler-logout' style={{color:'#FFFFFF'}} />
                    <Typography color='#FFFFFF'> Logout</Typography>
                  </MenuItem>




                  {/*<div className='flex items-center plb-2 pli-3'>*/}
                  {/*  <Button*/}
                  {/*    fullWidth*/}
                  {/*    variant='contained'*/}
                  {/*    color='error'*/}
                  {/*    size='small'*/}
                  {/*    endIcon={<i className='tabler-logout' />}*/}
                  {/*    onClick={handleUserLogout}*/}
                  {/*    sx={{ '& .MuiButton-endIcon': { marginInlineStart: 1.5 } }}*/}
                  {/*  >*/}
                  {/*    Logout*/}
                  {/*  </Button>*/}
                  {/*</div>*/}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Fade>
        )}
      </Popper>
    </>
  )
}

export default UserDropdown

'use client'

// React Imports
import {useRef, useEffect, useState} from 'react'

// MUI Imports
import IconButton from '@mui/material/IconButton'
import {useParams, useRouter} from "next/navigation";
import {getLocalizedUrl} from "@/utils/i18n";
import {useSession} from "next-auth/react";
import type {Locale} from "@configs/i18n";
import Tooltip from "@mui/material/Tooltip";


const PannelloUtenti = () => {
    const session = useSession({required: true})
    const [user, setUser]: any = useState(null)

    const router = useRouter()

    // Refs
    const anchorRef = useRef<HTMLButtonElement>(null)
    const ref = useRef<HTMLDivElement | null>(null)
    const {lang: locale}: any = useParams()

    useEffect(() => {
        const adjustPopoverHeight = () => {
            if (ref.current) {
                // Calculate available height, subtracting any fixed UI elements' height as necessary
                const availableHeight = window.innerHeight - 100

                ref.current.style.height = `${Math.min(availableHeight, 550)}px`
            }
        }

        window.addEventListener('resize', adjustPopoverHeight)
    }, [])

    useEffect(() => {
        if (session.status === 'authenticated') {
            const mySession: any = session.data

            setUser(mySession.user)
        }
    }, [session.status]);

    function goToAdmin() {
        router.push(getLocalizedUrl('/PannelloUtenti', locale as Locale))
    }

    return user?.role < 70 && (
        <>
            <Tooltip title={locale === 'it' ? 'Pannello Utenti' : 'Users Panel'}>
                <IconButton ref={anchorRef} onClick={goToAdmin} className='text-textPrimary'>
                    <i className='tabler-users'/>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default PannelloUtenti

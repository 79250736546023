export const i18n = {
  defaultLocale: 'it',
  locales: ['it','en'],
  langDirection: {
    it: 'ltr',
    en: 'ltr'
  }
} as const

export type Locale = (typeof i18n)['locales'][number]

'use client'

// React Imports
import {createContext, ReactElement, useContext} from 'react'

// Type Imports
import type {SystemMode} from '@core/types'

// Hook Imports
import {useSettings} from '@core/hooks/useSettings'
import useLayoutInit from '@core/hooks/useLayoutInit'
import React from 'react'
import {useSession} from "next-auth/react";

type LayoutWrapperProps = {
    systemMode: SystemMode
    verticalLayout: ReactElement
    horizontalLayout: ReactElement
}


const LayoutWrapper = (props: LayoutWrapperProps) => {
    // Props
    const {systemMode, verticalLayout, horizontalLayout} = props

    // Hooks
    const {settings} = useSettings()

    useLayoutInit(systemMode)

    const layout =
        settings.layout === 'horizontal'
            ? React.cloneElement(horizontalLayout)
            : React.cloneElement(verticalLayout)

    // Return the layout based on the layout context
    return (
            <div className='flex flex-col flex-auto' data-skin={settings.skin}>
                {layout}
            </div>
    )
}

export default LayoutWrapper

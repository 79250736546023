'use client'

// React Imports
import {useRef, useEffect, useState} from 'react'

// MUI Imports
import IconButton from '@mui/material/IconButton'
import {useParams, useRouter} from "next/navigation";
import {getLocalizedUrl} from "@/utils/i18n";
import type {Locale} from "@configs/i18n";
import {useSession} from "next-auth/react";
import Tooltip from "@mui/material/Tooltip";

const GoToHome = () => {
    const session = useSession({required: true})
    const [user, setUser]: any = useState(null)


    const router = useRouter()

    // Refs
    const anchorRef = useRef<HTMLButtonElement>(null)
    const ref = useRef<HTMLDivElement | null>(null)
    const {lang: locale}: any = useParams()
    const [text, setText] = useState('')

    useEffect(() => {
        const adjustPopoverHeight = () => {
            if (ref.current) {
                // Calculate available height, subtracting any fixed UI elements' height as necessary
                const availableHeight = window.innerHeight - 100

                ref.current.style.height = `${Math.min(availableHeight, 550)}px`
            }
        }

        window.addEventListener('resize', adjustPopoverHeight)
    }, [])
    useEffect(() => {
        if (session.status === 'authenticated') {
            const mySession: any = session.data

            setUser(mySession.user)

            if(locale){
                if(locale === 'it'){
                    setText(mySession.user.role >= 70 ? 'Home' : 'Pannello Amministratore' )
                }else{
                    setText(mySession.user.role >= 70 ? 'Home' : 'Admin Panel' )
                }
            }
        }
    }, [session.status]);

    function goToHome() {
        if (user.role < 70) {
            router.push(getLocalizedUrl('/PannelloAdmin', locale as Locale))
        } else {
            router.push(getLocalizedUrl(`/Home/${user.fornitoreId}`, locale as Locale))
        }
    }

    return (
        <>
            <Tooltip title={text}>
                <IconButton ref={anchorRef} onClick={goToHome} className='text-textPrimary'>
                    <i className='tabler-home'/>
                </IconButton>
            </Tooltip>
        </>
    )
}

export default GoToHome
